import React, { useEffect, useState } from "react";
import * as S from "./LandingPage.styles";
import { LoremIpsum } from "./ladningPageLorem";
import { Link, useNavigate, useParams } from "react-router-dom";

const LadningPage = () => {
  const { id: openedModalID } = useParams();
  const naviagate = useNavigate();
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const width = window.innerWidth;

  const tiles = [
    {
      name: <S.TileHeader>GB Akwarystyka</S.TileHeader>,
      id: 1,
      description: (
        <S.DescriptionText>
          GB Akwarystyka, profejsonalna hodowla i sprzedaz ryb akwariowych.
          Oława, Bystrzyca. Ryby, akwaria"
        </S.DescriptionText>
      ),
      images: [],
      tileImage: "tiles1",
      modalName: <S.ModalHeader>GB Akwarystyka</S.ModalHeader>,
    },
    {
      id: 2,
      name: <S.TileHeader>🎉 Grudniowa Promocja w Akwarium! 🎉</S.TileHeader>,
      modalName: (
        <S.ModalHeader>
          🎉 Skorzystaj z wyjątkowych grudniowych okazji na wybrane gatunki ryb
          akwariowych! 🎉
        </S.ModalHeader>
      ),
      description: (
        <>
          <S.DescriptionText>
            Z okazji nowego miesiąca mamy przyjemność zaoferować nowe promocje!
            🎉
          </S.DescriptionText>
          <S.DescriptionTextWithMargin>
            <ul>
              <li>
                <strong>Akra Red</strong> – tylko 4 zł/szt., przy zakupie
                minimum 5 sztuk.
              </li>
              <li>
                <strong>Pielęgnica Miodowa</strong> – 4 zł/szt., przy zakupie
                minimum 5 sztuk.
              </li>
              <li>
                <strong>Pielęgniczka Ramireza Standard Motylek</strong> – 5
                zł/szt., przy zakupie minimum 5 sztuk.
              </li>
              <li>
                <strong>Bocja siatkowana</strong> – 7 zł/szt., przy zakupie
                minimum 3 sztuk.
              </li>
            </ul>
          </S.DescriptionTextWithMargin>
          <strong>
            <Link to="/sklep/wszystkie">
              <S.AdditionalInfo>Sprawdź naszą ofertę</S.AdditionalInfo>
            </Link>
          </strong>
          <S.DescriptionTextWithMargin>
            📍 Odwiedź nas w sklepie po umówionej wizycie - 📞{" "}
            <strong>+48 698 954 898!</strong> <br />
            Godziny otwarcia: Poniedziałek - Sobota: 10:00 - 20:00 / Niedziela:
            15:00 - 20:00! <br />
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: ["akara-czerwona-2", "ramireza", "ramireza1"],
      tileImage: "akara-czerwona-1",
      customHeight: "300px",
    },
    {
      id: 7,
      name: <S.TileHeader>🎉 KONKURS 🎉</S.TileHeader>,
      modalName: (
        <S.ModalHeader>
          Mamy świetne wiadomości! Postanowiliśmy wprowadzić cykliczne konkursy
          abyście mogli podzielić się swoją pasją oraz mieli szansę wygrać
          przeróżne fanty!
        </S.ModalHeader>
      ),
      description: (
        <>
          <S.DescriptionText>
            Standardowe konkursy będą odbywać się raz na miesiąc, a raz na
            kwartał wylosujemy jedną osobę spośród wszystkich uczestników
            konkursów, która otrzyma pokaźny voucher do naszego sklepu! Pierwszy
            konkurs już{" "}
            <a href={"https://www.facebook.com/share/p/fG5NYwL9jZJBaM7g/"}>
              <S.AdditionalInfo>na naszym facebooku!</S.AdditionalInfo>
            </a>
          </S.DescriptionText>
          <S.DescriptionTextWithMargin>
            <strong>
              Nie czekaj i wygraj Purigen 200ml, grzałkę lub odkurzacz do żwiru!
            </strong>
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: ["vacum_big", "heater", "vac_small"],
      tileImage: "purigen",
      customHeight: "300px",
    },
    {
      id: 8,
      name: <S.TileHeader>🎉 Czyszczenia Akwariów - Malawi 🎉</S.TileHeader>,
      modalName: (
        <S.ModalHeader>🎉 Promocja na pyszczaki Malawi! 🎉</S.ModalHeader>
      ),
      description: (
        <>
          <S.DescriptionText>
            Z okazji <strong>czyszczenia zbiorników Malawi</strong> oferujemy
            wyjątkową promocję na ryby z grupy pyszczaków! 🎉
          </S.DescriptionText>
          <S.DescriptionTextWithMargin>
            Tylko teraz:
            <br />{" "}
            <strong>
              Pyszczaki po 6 zł/sztuka przy zakupie minimum 5 sztuk. Dostępne
              gatunki: <br /> Soulosi <br /> Yellow <br /> Mphanga <br /> Hongi
            </strong>
            <br />
            <strong>Super okazja:</strong>
            Przy zakupie zestawu 25 sztuk (5 gatunków po 5 sztuk) cena tylko 4
            zł za sztukę! <br /> Oferta ograniczona czasowo – spiesz się, zanim
            zniknie! 🌊 🛒
          </S.DescriptionTextWithMargin>
          <S.DescriptionTextWithMargin>
            📍 Odwiedź nas w sklepie po umówionej wizycie - 📞{" "}
            <strong>+48 698 954 898!</strong> <br />
            Godziny otwarcia: Poniedziałek - Sobota: 10:00 - 20:00 / Niedziela:
            15:00 - 20:00! <br />
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: ["malawi_1", "malawi_2", "malawi_3", "malawi_4", "malawi_5"],
      tileImage: "malawi",
      customHeight: "300px",
    },
    {
      id: 8,
      name: <S.TileHeader>Koty do oddania!</S.TileHeader>,
      modalName: <S.ModalHeader>Bezpańskie koty poszukują domu!</S.ModalHeader>,
      description: (
        <>
          <S.DescriptionText>
            <strong>Uwaga! Trzy koty szukają domu!</strong>
          </S.DescriptionText>
          <S.DescriptionTextWithMargin>
            Odbiór kotów w bystrzycy po wcześniejszym kontakcie telefonicznym!
          </S.DescriptionTextWithMargin>
          <S.DescriptionTextWithMargin>
            <strong>+48 698 954 898!</strong> <br />
          </S.DescriptionTextWithMargin>
        </>
      ),
      images: ["koty", "koty1"],
      tileImage: "koty",
      customHeight: "300px",
    },
  ];

  const [newsModalOpen, setNewsModalOpen] = useState(
    openedModalID ? Number(openedModalID) : -1
  );

  useEffect(() => {
    setNewsModalOpen(openedModalID ? Number(openedModalID) : -1);
  }, [openedModalID]);

  return (
    <S.MainPage>
      {width > 768 ? (
        <S.Slider>
          <S.Image
            onLoad={() => setIsSliderLoaded(true)}
            src={`${process.env.PUBLIC_URL}/assets/${
              isSliderLoaded ? "slider" : "lowSlider"
            }.webp`}
            loading="lazy"
          />
        </S.Slider>
      ) : null}
      <S.InformationSection>
        <S.InfomrationContentContainer>
          {width < 768 ? <S.MobileHeader>Aktualności:</S.MobileHeader> : null}
          <S.RowContainer>
            {tiles.map(({ name, tileImage }, index) => (
              <S.Tiles
                onClick={() => {
                  setNewsModalOpen(index);
                  naviagate(`/informacja/${index}`);
                }}
                key={tileImage + index}
              >
                <S.TileUpperContainer>
                  <S.TilesPhoto
                    src={`${process.env.PUBLIC_URL}/temporaryAssets/${tileImage}.webp`}
                  />
                </S.TileUpperContainer>
                <S.TileInfo>{name}</S.TileInfo>
              </S.Tiles>
            ))}
          </S.RowContainer>
          {/* {width < 768 ? (
            <div>
              <S.FooterHeader>
                Realizacja:{" "}
                <a target="_blank" href="https://www.gwmdigital.pl/">
                  <S.FooterSpan>gwmdigital.pl</S.FooterSpan>
                </a>
              </S.FooterHeader>
            </div>
          ) : null} */}
        </S.InfomrationContentContainer>
      </S.InformationSection>
      {newsModalOpen !== -1 ? (
        <>
          <S.NewsModal>
            <S.ReverseButton
              onClick={() => {
                setNewsModalOpen(-1);
                naviagate(`/`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="m15.547 2-1.305 1.27L6 11.293v1.414l8.242 8.022L15.547 22H17v-1.414l-1.305-1.271L8.18 12l7.515-7.316L17 3.414V2z"
                ></path>
              </svg>
            </S.ReverseButton>
            <S.PostImage
              src={`${process.env.PUBLIC_URL}/temporaryAssets/${tiles[newsModalOpen].tileImage}.webp`}
            />
            <S.Title>{tiles[newsModalOpen].modalName}</S.Title>
            {tiles[newsModalOpen].description}
            {tiles[newsModalOpen].images !== undefined &&
            tiles[newsModalOpen].images.length > 0 ? (
              <S.ImagesRow>
                {tiles[newsModalOpen].images.map((string) => (
                  <S.ModalImageContainer custom={30}>
                    <S.ModalImage
                      loading="lazy"
                      key={string}
                      src={`${process.env.PUBLIC_URL}/temporaryAssets/${string}.webp`}
                    />
                  </S.ModalImageContainer>
                ))}
              </S.ImagesRow>
            ) : null}
          </S.NewsModal>
          <S.ModalOverlay
            onClick={() => {
              setNewsModalOpen(-1);
              naviagate(`/`);
            }}
          />
        </>
      ) : null}
      {width < 768 ? null : (
        <S.Footer>
          <div>
            <a
              href={"https://www.facebook.com/profile.php?id=61564302763635"}
              target="_blank"
            >
              <S.SocialMediaIcon
                src={`${process.env.PUBLIC_URL}/assets/facebook.svg`}
                alt="facebook"
              />
            </a>
          </div>
          <div>
            <S.FooterHeader>
              Realizacja:{" "}
              <a target="_blank" href="https://www.gwmdigital.pl/">
                <S.FooterSpan>gwmdigital.pl</S.FooterSpan>
              </a>
            </S.FooterHeader>
          </div>
        </S.Footer>
      )}
    </S.MainPage>
  );
};

export default LadningPage;
